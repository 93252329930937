<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>站点管理</el-breadcrumb-item>
      <el-breadcrumb-item>站点列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.siteName" clearable placeholder="请输入站点名称"></el-input>
        <el-button type="primary" @click="search()">查询</el-button>
        <el-button>导出</el-button>
      </div>

      <!-- 站点信息框 -->
      <el-table :data="siteList" border stripe style="width: 100%"
        :header-cell-style="{ background: 'rgba(22, 119, 255, 0.0300)' }">
        <el-table-column type="index" align="center"> </el-table-column>
        <el-table-column prop="siteNum" label="站点编号" align="center">
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称" width="180" align="center">
        </el-table-column>
        <el-table-column prop="longitude" label="站点经度" width="145" align="center">
        </el-table-column>
        <el-table-column prop="latitude" label="站点纬度" width="145" align="center">
        </el-table-column>
        <el-table-column prop="stationmaster" label="站长名称" width="135" align="center">
        </el-table-column>
        <el-table-column prop="stationmasterAccount" label="站长账号" align="center">
        </el-table-column>
        <el-table-column prop="flag" label="站点状态" width="124" align="center">
          <template slot-scope="scope">{{ map1[scope.row.flag] }}</template>
        </el-table-column>
        <el-table-column label="操作" width="275" align="center">
          <!-- <el-button type="warning"
                     size="mini"
                     icon="el-icon-edit"
                     @click="vehicleEditor=true">编辑</el-button>
          <el-button type="danger"
                     size="mini"
                     icon="el-icon-delete"
                     @click="vehicleEditor=true">删除</el-button> -->
        </el-table-column>

      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.current" :page-sizes="[10, 20, 50, 100, 200]" :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

  </div>
</template>
<script>
export default {
  data() {
    return {
      // 控制添加站点对话框的显示与隐藏
      addDialogVisible: false,
      // 获取员工列表
      siteList: [],
      queryInfo: {
        siteName: '',
        keyword: '',
        current: 1,
        size: 10,
      },
      total: 0,
      map1: ['禁用', '启用'],
    }
  },
  created() {
    this.getSiteList()
  },
  methods: {
    // 获取站点列表
    getSiteList() {
      this.axios.post('/api/site/info/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.siteList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      //   console.log(newSize)
      this.queryInfo.size = newSize
      this.getSiteList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      //   console.log(newPage)
      this.queryInfo.current = newPage
      this.getSiteList()
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getSiteList
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getSiteList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}

.el-input {
  margin-right: 8px;
  width: 240px;
}

.el-card {
  padding: 0 20px;
  margin-bottom: 20px;
}

.change {
  margin: 20px 0;
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
    
  